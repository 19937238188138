import { UserSettingsConversationConfig } from 'libs/components/legacy/messenger/model/interface';

import { Profile } from './profile.model';
import { Attachment } from './attachment.model';
import { Pagination } from './data-access';
import { Customer } from './customer.model';
import { Address } from './address.model';
import { SystemTag } from './system-tag.model';
import { SearchPredicate } from './search-predicate.model';
import { PropertyApplicationStatus } from './enums';
import { CustomData } from './custom-data.model';

export interface User {
  // LANDLORD DATA
  id?: string;
  created?: string;
  updated?: string;
  lastLogin?: string;
  email?: string;
  enabled?: boolean;
  expired?: boolean;
  locked?: boolean;
  profile?: Profile;
  preferences?: { [key: string]: any };
  usertype?: string;
  authorities?: string[];
  username?: string;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  attachments?: {
    nodes?: Attachment[];
    page?: Pagination;
  };
  customer?: Customer;
  hasCustomerProduct?: boolean;
  viewings?: any;
  emailVerified?: string;

  // PROPERTYSEARCHER DATA
  anonymous?: boolean;
  address?: Address;
  status?: string;
  new?: boolean;
  profileCompleteness?: number;
  comments?: any[];
  rating?: number;
  applications?: any;
  name?: string;
  firstname?: string;

  logo?: string;
}

export interface UserSettings {
  followupSettingsData: FollowupSettings;
  customDataModalSettingsData?: CustomDataModalSettingsData;
  filterSettings: FilterSettings;
  conversationConfig: UserSettingsConversationConfig;
  appointmentSettings: AppointmentSettings;
}

export interface AppointmentSettings {
  blockMinutesBeforeStart?: number;
}

export interface ProductBoardUrl {
  url?: string;
}

export interface FilterSettings {
  agentIds: string[];
  searchTerm: string;
  applicationListSettings: PAListSettings;
  proposalListSettings: PAListSettings;
}

export interface PAListSettings {
  tagFilter?: {
    systemTags?: SystemTag[];
    tagIds?: string[];
    searchPredicate?: SearchPredicate;
  };
  sort?: string;
  sortBy?: string;
  sortOrder?: string;
  size?: number;
  filterOptions?: string[];
  statuses?: PropertyApplicationStatus[];
}

export interface FollowupSettings {
  notificationTimeIntervals: number[];
}

export interface CustomDataModalSettingsData {
  applicationData?: CustomDataModalSettingFields;
  proposalData?: CustomDataModalSettingFields;
}

export interface CustomDataModalSettingFields {
  fields: CustomData[];
  isAnonymised: boolean;
}

export enum ContactType {
  PROPERTY_SEARCHER = 'PROPERTY_SEARCHER',
  AGENT = 'AGENT',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER'
}

export type UserRegisteredPlatform = {
  registeredAtImmomio: boolean;
};
